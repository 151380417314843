import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../../components/bio"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { rhythm } from "../../utils/typography"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="All posts"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <Bio />

        <h1>My digital garden</h1>

        <p>
          these posts really resonated with me, splitting posts to thoughts in a
          clear manner
        </p>
        <p>
          Notes are thigns that I care about that are too long to fit in a tweet
          and too shallow or raw to be a proper blogpost, nevertheless is
          something that can be interesting to others person or a starting point
          for a conversation able to shape the note to his final form.
        </p>

        <p>
          in my notes I will talk about work in progress experiments, dreams and
          idea
        </p>

        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <div key={node.fields.slug}>
              <h3
                className="index-post-title"
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  fontSize: rhythm(1),
                  marginBottom: 0,
                }}
              >
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <p style={{ marginBottom: rhythm(1 / 2) }}>
                <small>
                  {node.frontmatter.date} | {node.timeToRead} min read
                </small>
              </p>

              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </div>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fields: { collection: { eq: "notes" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          timeToRead
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM YYYY")
            title
            tags
          }
        }
      }
    }
  }
`
